import { useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

export const getEstimates = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/api/light/estimates/', q);
  return data;
};

export const useEstimates = (q: Record<string, any>) => {
  return useQuery(['estimates', q], () => getEstimates(q), {
    staleTime: 0,
  });
};

const getEstimateContract = async (id: string) => {
  const { data } = await apiService.get(`/api/light/estimates/${id}/contract/`);
  return data;
};

export const useEstimateContract = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'contract'],
    () => getEstimateContract(id),
    {
      staleTime: 0,
    }
  );
};

const getEstimateMeta = async (id: string) => {
  const { data } = await apiService.get(`/api/light/estimates/${id}/meta/`);
  return data;
};

export const useEstimateMeta = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'meta'],
    () => getEstimateMeta(id),
    {
      staleTime: 0,
    }
  );
};

const getLineitemSummary = async (id: string) => {
  const { data } = await apiService.get(`/api/light/estimates/${id}/summary/`);
  return data;
};

export const useLineitemSummary = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'summary'],
    () => getLineitemSummary(id),
    {
      staleTime: 0,
    }
  );
};

const getWorkDoingLineitems = async (id: string, hide = false) => {
  if (hide) {
    return {};
  }
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/work_doing_lineitems/`
  );
  return data;
};

export const useWorkDoingLineitems = (id: string, hide = false) => {
  return useQuery(
    ['estimates', id?.toString(), 'work-doing-lineitems', hide],
    () => getWorkDoingLineitems(id, hide),
    {
      staleTime: 0,
    }
  );
};

const getWorkNotDoingLineitems = async (id: string, hide = false) => {
  if (hide) {
    return {};
  }
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/work_not_doing_lineitems/`
  );
  return data;
};

export const useWorkNotDoingLineitems = (id: string, hide = false) => {
  return useQuery(
    ['estimates', id?.toString(), 'work-not-doing-lineitems', hide],
    () => getWorkNotDoingLineitems(id, hide),
    {
      staleTime: 0,
    }
  );
};

const getSuppLineitems = async (id: string, hide = false) => {
  if (hide) {
    return {};
  }
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/supplements_lineitems/`
  );
  return data;
};

export const useSuppLineitems = (id: string, hide = false) => {
  return useQuery(
    ['estimates', id?.toString(), 'supplements-lineitems', hide],
    () => getSuppLineitems(id, hide),
    {
      staleTime: 0,
    }
  );
};

const getUpgradesLineitems = async (id: string, hide = false) => {
  if (hide) {
    return {};
  }
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/upgrades_lineitems/`
  );
  return data;
};

export const useUpgradesLineitems = (id: string, hide = false) => {
  return useQuery(
    ['estimates', id?.toString(), 'upgrades-lineitems', hide],
    () => getUpgradesLineitems(id, hide),
    {
      staleTime: 0,
    }
  );
};

const getDiscountsLineitems = async (id: string, hide = false) => {
  if (hide) {
    return {};
  }
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/discounts_lineitems/`
  );
  return data;
};

export const useDiscountsLineitems = (id: string, hide = false) => {
  return useQuery(
    ['estimates', id?.toString(), 'discounts-lineitems', hide],
    () => getDiscountsLineitems(id, hide),
    {
      staleTime: 0,
    }
  );
};

const getChangeOrderLineitems = async (id: string, hide = false) => {
  if (hide) {
    return {};
  }
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/change_orders_lineitems/`
  );
  return data;
};

export const useChangeOrderLineitems = (id: string, hide = false) => {
  return useQuery(
    ['estimates', id?.toString(), 'change-orders-lineitems', hide],
    () => getChangeOrderLineitems(id, hide),
    {
      staleTime: 0,
    }
  );
};

const getEstimateMoreInfo = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/job_detail/`
  );
  return data;
};

export const useEstimateMoreInfo = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'more-info'],
    () => getEstimateMoreInfo(id),
    {
      staleTime: 0,
    }
  );
};

const getPaymentSchedule = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/payment_schedule/`
  );
  return data;
};

export const usePaymentSchedule = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'payment-schedule'],
    () => getPaymentSchedule(id),
    {
      staleTime: 0,
    }
  );
};

const getEstimateLineitems = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/lineitems/`
  );
  return data;
};

export const useEstimateLineitems = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'lineitems'],
    () => getEstimateLineitems(id),
    {
      staleTime: 0,
    }
  );
};

const getEstimateChecklist = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/checklist/`
  );
  return data;
};

export const useEstimateChecklist = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'checklist'],
    () => getEstimateChecklist(id),
    {
      staleTime: 0,
    }
  );
};

const getEstimateFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/estimates/fields_config/',
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
}

export const useEstimateFieldConfig = (q: IFieldConfig = {}) => {
  const { job } = q;
  return useQuery(
    ['estimates', 'field-config', { job }],
    () => getEstimateFieldConfig({ job }),
    {
      staleTime: 0,
    }
  );
};

// ** mutations *****
export const createEstimate = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post('/api/light/estimates/', payload, q);
  return data;
};

export const createEstimateFromDrawing = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/rooflayout_estimate/`,
    payload
  );
  return data;
};

export const createLightEstimateFromDrawing = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/light_rooflayout_estimate/`,
    payload
  );
  return data;
};

export const createEstimateFromMeasurement = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/manual_rooflayout_estimate/`,
    payload
  );
  return data;
};

export const createLightEstimateFromMeasurement = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/light_manual_rooflayout_estimate/`,
    payload
  );
  return data;
};

export const createSumoquote = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/create_sumoquote/`,
    payload
  );
  return data;
};

export const removeSumoquote = async (jobId: string) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/remove_sumoquote/`
  );
  return data;
};

export const deleteEstimate = async (id: string) => {
  await apiService.delete(`/api/light/estimates/${id}/`);
};

// ****** Lineitems related queries ******
interface ILineitemFieldConfig {
  estimate?: string;
  lineitem?: string;
  lineitem_type?: string;
}

const getLineItemFieldConfig = async (q: Record<string, any>) => {
  if (q?.lineitem_type === 'supplements') {
    const { data } = await apiService.get(
      `/api/light/estimate-supplineitems/fields_config/`,
      q
    );
    return data;
  }
  if (q?.lineitem_type === 'changeorders') {
    const { data } = await apiService.get(
      `/api/light/estimate-changeorderlineitems/fields_config/`,
      q
    );
    return data;
  }
  const { data } = await apiService.get(
    `/api/light/estimate-lineitems/fields_config/`,
    q
  );
  return data;
};

export const useLineItemFieldConfig = (q: ILineitemFieldConfig = {}) => {
  const { estimate } = q;
  return useQuery(
    ['estimates', estimate?.toString(), 'lineitems-field-config', q],
    () => getLineItemFieldConfig(q),
    {
      staleTime: q.lineitem ? 0 : Infinity,
    }
  );
};

const getRLTemplateAndSupplierFieldConfig = async (
  jobId: string,
  q?: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/rooflayout_select_template_and_supplier_field_config/`,
    q
  );
  return data;
};

export const useRLTemplateAndSupplierFieldConfig = (
  jobId: string,
  q?: Record<string, any>
) => {
  return useQuery(
    ['rl-template-supplier-field-config', jobId],
    () => getRLTemplateAndSupplierFieldConfig(jobId, q),
    { staleTime: 0 }
  );
};

const getEstimateFromTemplateFieldConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/estimate_from_estimate_template_config/`
  );
  return data;
};

export const useEstimateFromTemplateFieldConfig = (jobId: string) => {
  return useQuery(
    ['estimates', 'from_template', jobId],
    () => getEstimateFromTemplateFieldConfig(jobId),
    { staleTime: 0 }
  );
};

export const updateEstimate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(`/api/light/estimates/${id}/`, payload);
  return data;
};

export const createChangeOrderLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/estimate-changeorderlineitems/create_changeorder_lineitem/',
    payload,
    q
  );
  return data;
};

export const updateChangeOrderLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimate-changeorderlineitems/${id}/update_changeorder_lineitem/`,
    payload,
    q
  );
  return data;
};

export const createSupplineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/estimate-supplineitems/create_supplineitem/',
    payload,
    q
  );
  return data;
};

export const updateSupplineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimate-supplineitems/${id}/update_supplineitem/`,
    payload,
    q
  );
  return data;
};

export const createWorkDoingLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/estimate-lineitems/create_lineitem_work_doing/',
    payload,
    q
  );
  return data;
};

export const updateWorkDoingLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimate-lineitems/${id}/update_lineitem_work_doing/`,
    payload,
    q
  );
  return data;
};

export const createWorkNotDoingLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/estimate-lineitems/create_lineitem_work_not_doing/',
    payload,
    q
  );
  return data;
};

export const updateWorkNotDoingLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimate-lineitems/${id}/update_lineitem_work_not_doing/`,
    payload,
    q
  );
  return data;
};

export const createUpgradeLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/estimate-lineitems/create_lineitem_upgrade/',
    payload,
    q
  );
  return data;
};

export const updateUpgradeLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimate-lineitems/${id}/update_lineitem_upgrade/`,
    payload,
    q
  );
  return data;
};

export const createDiscountLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/estimate-lineitems/create_lineitem_discount/',
    payload,
    q
  );
  return data;
};

export const updateDiscountLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimate-lineitems/${id}/update_lineitem_discount/`,
    payload,
    q
  );
  return data;
};

export const deleteLineitem = async (id: string) => {
  await apiService.delete(`/api/light/estimate-lineitems/${id}/`);
};

export const deleteSupplineitem = async (id: string) => {
  await apiService.delete(`/api/light/estimate-supplineitems/${id}/`);
};

export const convertToWorkDoing = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/estimate-lineitems/${id}/convert_to_work_doing/`
  );
  return data;
};

export const convertToWorkNotDoing = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/estimate-lineitems/${id}/convert_to_work_not_doing/`
  );
  return data;
};

export const generateMatorder = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/estimates/${id}/generate_matorder/`,
    payload
  );
  return data;
};

export const editDeliveryInstructions = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimates/${id}/edit_delivery_instructions/`,
    payload
  );
  return data;
};

export const editExistingDamage = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimates/${id}/edit_existing_damage/`,
    payload
  );
  return data;
};

export const editWorkNotDoingText = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimates/${id}/edit_work_not_doing_text/`,
    payload
  );
  return data;
};

export const saveCustomerInvoice = async (id: string, sync = false) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/save_pdf_cust_2/?sync=${sync}`
  );
  return data;
};

export const saveInsuranceInvoice = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/save_pdf_ins/`
  );
  return data;
};

export const saveCustomerInvoiceCompletedOnly = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/save_pdf_cust_completed_only/`
  );
  return data;
};

export const editJobOverhead = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/estimates/${id}/edit_overhead/`,
    payload
  );
  return data;
};

export const editGtMargin = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/estimates/${id}/edit_gt_margin/`,
    payload
  );
  return data;
};

const getSupplineItemFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimate-supplineitems/${id}/approve_fields_config/`
  );
  return data;
};

export const useApproveSupplinItemFieldConfig = (
  id: string,
  estimateId = ''
) => {
  return useQuery(
    ['estimates', estimateId?.toString(), 'suppline-item-field-config', id],
    () => getSupplineItemFieldConfig(id),
    { staleTime: 0 }
  );
};

const getFinanceFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/finance_fields_config/`
  );
  return data;
};

export const useFinanceFieldConfig = (id: string) => {
  return useQuery(
    ['estimates', id?.toString(), 'finance-field-config'],
    () => getFinanceFieldConfig(id),
    { staleTime: 0 }
  );
};

export const updateSupplineitemApprove = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/estimate-supplineitems/${id}/update_approve/`,
    payload
  );
  return data;
};

export const convertToCashMode = async (id: string) => {
  const { data } = await apiService.patch(
    `/api/light/estimates/${id}/convert_payment_mode/`,
    { finance: false }
  );
  return data;
};

export const convertToFinanceMode = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/estimates/${id}/finance_mode/`,
    payload
  );
  return data;
};

export const relateLineItemPhotos = async (id: string, photos: string[]) => {
  const { data } = await apiService.post(
    `/api/light/estimate-lineitems/${id}/relate_photos/`,
    { photos }
  );
  return data;
};

export const moveLineItem = async (id: string, order: number) => {
  const { data } = await apiService.post(
    `/api/light/estimate-lineitems/${id}/move_to/`,
    { order }
  );
  return data;
};

export const relateSuppLineItemPhotos = async (
  id: string,
  photos: string[]
) => {
  const { data } = await apiService.post(
    `/api/light/estimate-supplineitems/${id}/relate_photos/`,
    { photos }
  );
  return data;
};

export const moveSuppLineItem = async (id: string, order: number) => {
  const { data } = await apiService.post(
    `/api/light/estimate-supplineitems/${id}/move_to/`,
    { order }
  );
  return data;
};

export const relateChangeOrderLineItemPhotos = async (
  id: string,
  photos: string[]
) => {
  const { data } = await apiService.post(
    `/api/light/estimate-changeorderlineitems/${id}/relate_photos/`,
    { photos }
  );
  return data;
};

export const moveChangeOrderLineItem = async (id: string, order: number) => {
  const { data } = await apiService.post(
    `/api/light/estimate-changeorderlineitems/${id}/move_to/`,
    { order }
  );
  return data;
};

export const deleteChangeOrderLineItem = async (id: string) => {
  await apiService.delete(`/api/light/estimate-changeorderlineitems/${id}/`);
  return true;
};

export const unrelateLineItemPhotos = async (id: string, photos: string[]) => {
  const { data } = await apiService.post(
    `/api/light/estimate-lineitems/${id}/unrelate_photos/`,
    { photos }
  );
  return data;
};

export const unrelateSuppLineItemPhotos = async (
  id: string,
  photos: string[]
) => {
  const { data } = await apiService.post(
    `/api/light/estimate-supplineitems/${id}/unrelate_photos/`,
    { photos }
  );
  return data;
};

export const unrelateChangeOrderLineItemPhotos = async (
  id: string,
  photos: string[]
) => {
  const { data } = await apiService.post(
    `/api/light/estimate-changeorderlineitems/${id}/unrelate_photos/`,
    { photos }
  );
  return data;
};

export const uploadInsReviewDoc = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/estimates/${id}/upload_ins_review/`,
    payload
  );
  return data;
};

export const changeInsReviewDate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/estimates/${id}/edit_date_ins_review/`,
    payload
  );
  return data;
};

export const syncQB = async (id: string) => {
  const { data } = await apiService.post(`/api/estimates/${id}/qb_sync/`);
  return data;
};

export const updateLineitem = async (
  lineitemId: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/lineitems/${lineitemId}/`,
    payload
  );
  return data;
};

export const getUnpaidEstimates = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get(`/api/light/unpaid-estimates/`, {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useDesktopUnpaidEstimates = (
  q?: Record<string, any>,
  page = 1
) => {
  return useQuery(
    ['estimates', 'unpaid', 'desktop', q, { page }],
    () => getUnpaidEstimates({ pageParam: page, ...q }),
    {
      staleTime: 0,
      keepPreviousData: true,
    }
  );
};

const getMatorderChoices = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/estimates/${id}/matorder_choices/`
  );
  return data;
};

export const useMatorderChoices = (id: string) => {
  return useQuery(
    ['estimates', id, 'matorder-choices'],
    () => getMatorderChoices(id),
    {
      staleTime: 0,
    }
  );
};

export const createEstimateTemplate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/estimates/${id}/save_as_estimate_template/`,
    payload
  );
  return data;
};

export const createEstimateFromTemplate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${id}/create_estimate_from_estimate_template/`,
    payload
  );
  return data;
};

export const importLineitemsFromTemplate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/estimates/${id}/update_estimate_from_estimate_template/`,
    payload
  );
  return data;
};

export const updateUpgradesText = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimates/${id}/update_upgrades_text/`,
    payload
  );
  return data;
};

export const updateDiscountsText = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/estimates/${id}/update_discounts_text/`,
    payload
  );
  return data;
};
