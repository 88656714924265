import { useMutation, useQueryClient } from '@tanstack/react-query';
import BottomSheet from 'components/BottomSheet';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { serverTimeToDate, now } from 'utils/time';
import Icon from 'components/Icon';
import { Layout } from 'components/Form';
import Button from 'components/Button';

type AppProps = {
  jobId: string;
  title: string;
  label: string;
  open: boolean;
  showComment?: boolean;
  closeModal: () => any;
  initial_date?: string;
  mutationFn: any;
  showTimeSelect?: boolean;
  dateFormat?: string;
};

interface IFormValues {
  date: Date;
  comment?: string;
}

const DateForm: React.FC<AppProps> = ({
  jobId,
  label,
  title,
  open,
  closeModal,
  initial_date = '',
  mutationFn,
  showComment = false,
  showTimeSelect = true,
  dateFormat = 'MM/dd/yyyy h:mm aa',
}) => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (formData: any) => mutationFn(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
        closeModal();
      },
    }
  );

  const schema = yup.object({
    date: yup.date().nullable(),
    comment: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const { comment, date } = data;
    let payload: Record<string, any> = {
      date,
    };

    if (showComment) {
      payload = { ...payload, comment };
    }

    updateMutation.mutate(payload);
  };

  useEffect(() => {
    const defaultValues: any = {};
    if (initial_date) {
      defaultValues['date'] = serverTimeToDate(initial_date);
    } else {
      defaultValues['date'] = now();
    }

    reset(defaultValues);
  }, [initial_date]);

  let fields: any[] = [
    {
      name: 'date',
      type: FieldType.DatePicker,
      label: label,
      placeholder: label,
      error: errors.date?.message,
      isClearable: true,
      showPastDates: true,
      showTimeSelect,
      control,
      dateFormat,
    },
  ];

  if (showComment) {
    fields = [
      ...fields,
      {
        type: FieldType.TextArea,
        label: 'Comment',
        placeholder: 'Type here...',
        error: errors.comment?.message,
        row: 4,
        ...register('comment'),
      },
    ];
  }

  return (
    <BottomSheet open={open}>
      <div className="pb-4">
        <div className="flex justify-between pb-6">
          <div className="page-heading">{title}</div>
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Layout fields={fields} loading={updateMutation.isLoading} />
          <Button
            label="Submit"
            className="mt-8"
            loading={updateMutation.isLoading}
          />
        </form>
      </div>
    </BottomSheet>
  );
};

export default DateForm;
