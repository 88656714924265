import React, { useState, useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import { GREY_COLOR, PRIMARY_COLOR, TEXT_COLOR } from 'utils/constants';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from 'use-places-autocomplete';
import lodash from 'lodash';
import { formatAddressDetails } from 'pages/Map/utils';

type AppProps = {
  name: string;
  control: any;
  setInitial?: boolean;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  autoFocus?: boolean;
  className?: string;
  error?: string;
  onBlur?: () => void;
  required?: boolean;
  loading?: boolean;
  onlyAddress?: boolean;
  disabled?: boolean;
};

const AddressSearch: React.FC<AppProps> = ({
  name,
  setInitial = false,
  label = '',
  control,
  placeholder = 'Search address...',
  isClearable = false,
  autoFocus = false,
  className = '',
  required = false,
  error = null,
  onBlur = () => null,
  onlyAddress = false,
  disabled = false,
}) => {
  const { field } = useController({ name, control });
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const {
    suggestions: { data, loading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'us',
      },
    },
    debounce: 300,
  });

  const options = data.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id,
  }));

  const getOptions = () => {
    if (options.length) {
      return options;
    }
    if (setInitial && field.value) {
      if (typeof field.value === 'string') {
        return [
          {
            label: field.value,
            value: field.value,
          },
        ];
      } else {
        return [
          {
            label: field.value?.formattedAddress,
            value: field.value,
          },
        ];
      }
    }
    return [];
  };

  const _options = getOptions();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleChangeOption = async (option: any) => {
    const [geoDetails, detail] = await Promise.all([
      getGeocode({ placeId: option.value }),
      getDetails({ placeId: option.value }),
    ]);

    if (!geoDetails) return null;

    const data = geoDetails[0];
    const { lat, lng } = getLatLng(data);
    const zipcode = getZipCode(data, false);
    const formattedDetails = formatAddressDetails(detail);

    const payload = {
      placeId: option.value,
      latitude: parseFloat(lat.toFixed(8)),
      longitude: parseFloat(lng.toFixed(8)),
      ...formattedDetails,
      zipcode,
    };

    field.onChange(onlyAddress ? payload.formattedAddress : payload);
    setInputValue(option.label);
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setValue(value);
    setIsOpen(true);
  };

  const handleClear = () => {
    setInputValue('');
    field.onChange(onlyAddress ? '' : {});
  };

  const inputClassName = clx(
    'w-full px-3 py-2 text-sm rounded-lg outline-none transition-colors',
    'border focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border': !error,
      'bg-gray-100 cursor-not-allowed': disabled,
    }
  );

  return (
    <div className={`space-y-1 mb-2 ${className}`}>
      {label && (
        <div className="text-text-medium text-sm">
          {label}
          {required && <span className="text-error">*</span>}
        </div>
      )}

      <div className="relative" ref={dropdownRef}>
        <div className="relative">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={onBlur}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={inputClassName}
          />
          {isClearable && inputValue && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              ×
            </button>
          )}
        </div>

        {isOpen && (
          <div className="absolute z-50 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto">
            {loading && (
              <div className="px-4 py-2 text-sm text-gray-500">Loading...</div>
            )}

            {!loading && _options.length === 0 && (
              <div className="px-4 py-2 text-sm text-gray-500">
                {!inputValue ? 'Start typing location...' : 'No place found'}
              </div>
            )}

            {_options.map((option) => (
              <div
                key={option.value}
                className="px-4 py-2 text-sm cursor-pointer hover:bg-primary hover:text-white"
                onClick={() => handleChangeOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>

      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

AddressSearch.displayName = 'AddressSearch';

export default AddressSearch;
