import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******* Get Register sources *********
const getRegisterSources = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/register_sources/'
  );
  return data;
};

export const useRegisterSources = (enabled = true) => {
  return useQuery(['register-sources'], getRegisterSources, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get Lead sources *********
const getLeadSources = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/lead_sources/'
  );
  return data;
};

export const useLeadSources = (enabled = true) => {
  return useQuery(['lead-sources'], getLeadSources, {
    enabled,
    staleTime: Infinity,
  });
};

const getProductCache = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/product_cache/'
  );
  return data;
};

export const useProductCache = () => {
  return useQuery(['product-cache'], getProductCache, { staleTime: Infinity });
};

export const syncProductCache = async () => {
  const { data } = await apiService.post('/api/light/companyproducts/sync/');
  return data;
};

// ******* Get Rep choices *********
const getReps = async () => {
  const { data } = await apiService.get('/api/light/company-resources/reps/');
  return data;
};

export const useRepChoices = (enabled = true) => {
  return useQuery(['rep-choices'], getReps, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get Alt Rep choices *********
const getAltReps = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/alt_reps/'
  );
  return data;
};

export const useAltRepChoices = (enabled = true) => {
  return useQuery(['alt-rep-choices'], getAltReps, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get Marketing Rep Choices *********
const getMarketingReps = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/marketing_reps/'
  );
  return data;
};

export const useMarketingRepChoices = (enabled = true) => {
  return useQuery(['marketing-rep-choices'], getMarketingReps, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get Project Managers *********
const getProjectManagers = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/project_managers/'
  );
  return data;
};

export const useProjectManagerChoices = (enabled = true) => {
  return useQuery(['project-manager-choices'], getProjectManagers, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get Management Companies *********
const getManagementCompanies = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/management_companies/'
  );
  return data;
};

export const useManagementCompanyChoices = (enabled = true) => {
  return useQuery(['management-company-choices'], getManagementCompanies, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get Insurance Reviewers *********
const getInsuranceReviewers = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/insurance_reviewers/'
  );
  return data;
};

export const useInsuranceReviewerChoices = (enabled = true) => {
  return useQuery(['insurance-reviewers-choices'], getInsuranceReviewers, {
    enabled,
    staleTime: Infinity,
  });
};

// ********** Get photo tags ************
const getPhotoTags = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/photo_tags/'
  );
  return data;
};

export const usePhotoTagChoices = (enabled = true) => {
  return useQuery(['company-tags', 'photo'], getPhotoTags, {
    enabled,
    staleTime: Infinity,
  });
};

// ********** Get note tags ************
const getNoteTags = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/note_tags/'
  );
  return data;
};

export const useNoteTagChoices = (enabled = true) => {
  return useQuery(['company-tags', 'note'], getNoteTags, {
    enabled,
    staleTime: Infinity,
  });
};

// ********** Get event tags ************
const getEventTags = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/event_tags/'
  );
  return data;
};

export const useEventTagChoices = (enabled = true) => {
  return useQuery(['company-tags', 'event'], getEventTags, {
    enabled,
    staleTime: Infinity,
  });
};

const getDocumentTags = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/document_tag_choices/'
  );
  return data;
};

export const useDocumentTagChoices = (enabled = true) => {
  return useQuery(['company-tags', 'document'], getDocumentTags, {
    enabled,
    staleTime: Infinity,
  });
};

const getAllTags = async (id: string) => {
  const { data } = await apiService.get(`/api/companies/${id}/tags/`);
  return data;
};

export const useAllTags = (id: string) => {
  return useQuery(['company-tags', 'all'], () => getAllTags(id), {
    staleTime: 30 * 60 * 1000,
  });
};

export const updateTags = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.put(`/api/companies/${id}/tags/`, payload);
  return data;
};

// ******** event invitees **********
const getEventInvitees = async (jobId?: string) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/event_invitees/',
    { job: jobId }
  );
  return data;
};

export const useEventInviteesChoices = (jobId?: string, enabled = true) => {
  return useQuery(['event-invitees', jobId], () => getEventInvitees(jobId), {
    enabled,
    staleTime: Infinity,
  });
};

// ******** Get event reminder choices *******
const getEventReminderChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/event_reminder_choices/'
  );
  return data;
};

export const useEventReminderChoices = (enabled = true) => {
  return useQuery(['event-reminder-choices'], getEventReminderChoices, {
    enabled,
    staleTime: Infinity,
  });
};

// ******** Get todo priority choices *******
const getTodoPriorityChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/todo_priority_choices/'
  );
  return data;
};

export const useTodoPriorityChoices = (enabled = true) => {
  return useQuery(['todo-priority-choices'], getTodoPriorityChoices, {
    enabled,
    staleTime: Infinity,
  });
};

// ********** Get todo tags ************
const getTodoTags = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/todo_tags/'
  );
  return data;
};

export const useTodoTagChoices = (enabled = true) => {
  return useQuery(['todo-tags'], getTodoTags, {
    enabled,
    staleTime: Infinity,
  });
};

const getTodoAssignees = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/todo_assignees/'
  );
  return data;
};

export const useTodoAssigneesChoices = (enabled = true) => {
  return useQuery(['todo-assignees'], getTodoAssignees, {
    enabled,
    staleTime: Infinity,
  });
};

const getInspectionTemplates = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/inspection_template_choices/'
  );
  return data;
};

export const useInspectionTemplateChoices = (enabled = true) => {
  return useQuery(['inspection-templates'], getInspectionTemplates, {
    enabled,
    staleTime: Infinity,
  });
};

const getPreInspectionTemplates = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/pre_inspection_template_choices/'
  );
  return data;
};

export const usePreInspectionTemplateChoices = (enabled = true) => {
  return useQuery(['pre-inspection-templates'], getPreInspectionTemplates, {
    enabled,
    staleTime: Infinity,
  });
};

const getBidTypes = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/bid_type_choices/'
  );
  return data;
};

export const useBidTypeChoices = (enabled = true) => {
  return useQuery(['bid-types'], getBidTypes, {
    enabled,
    staleTime: Infinity,
  });
};

const getJobTypes = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/job_type_choices/'
  );
  return data;
};

export const useJobTypeChoices = (enabled = true) => {
  return useQuery(['job-types'], getJobTypes, {
    enabled,
    staleTime: Infinity,
  });
};

const getTrades = async () => {
  const { data } = await apiService.get('/api/light/company-resources/trades/');
  return data;
};

export const useTradeChoices = (enabled = true) => {
  return useQuery(['trades'], getTrades, {
    enabled,
    staleTime: Infinity,
  });
};

const getAllTrades = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/all_trades/'
  );
  return data;
};

export const useAllTradeChoices = (enabled = true) => {
  return useQuery(['all-trades'], getAllTrades, {
    enabled,
    staleTime: Infinity,
  });
};

const getUnits = async () => {
  const { data } = await apiService.get('/api/light/company-resources/units/');
  return data;
};

export const useUnitChoices = (enabled = true) => {
  return useQuery(['units'], getUnits, {
    enabled,
    staleTime: Infinity,
  });
};

const getProducts = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/products/'
  );
  return data;
};

export const useProductChoices = (enabled = true) => {
  return useQuery(['products'], getProducts, {
    enabled,
    staleTime: Infinity,
  });
};

const getAllProducts = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/active_products/'
  );
  return data;
};

export const useAllProductChoices = (enabled = true) => {
  return useQuery(['all-products'], getAllProducts, {
    enabled,
    staleTime: Infinity,
  });
};

const getRoofComponentChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/roof_component_choices/'
  );
  return data;
};

export const useRoofComponentChoices = (enabled = true) => {
  return useQuery(['roof-components'], getRoofComponentChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getDiscountProducts = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/discount_products/'
  );
  return data;
};

export const useDiscountProductChoices = (enabled = true) => {
  return useQuery(['products-discount'], getDiscountProducts, {
    enabled,
    staleTime: Infinity,
  });
};

const getUpgradeProducts = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/upgrade_products/'
  );
  return data;
};

export const useUpgradeProductChoices = (enabled = true) => {
  return useQuery(['products-upgrade'], getUpgradeProducts, {
    enabled,
    staleTime: Infinity,
  });
};

const getPaymentMethods = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/payment_method_choices/'
  );
  return data;
};

export const usePaymentMethodChoices = (enabled = true) => {
  return useQuery(['payment-methods'], getPaymentMethods, {
    enabled,
    staleTime: Infinity,
  });
};

const getPaymentLabels = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/payment_label_choices/'
  );
  return data;
};

export const usePaymentLabelChoices = (enabled = true) => {
  return useQuery(['products'], getPaymentLabels, {
    enabled,
    staleTime: Infinity,
  });
};

const getSuppliers = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/suppliers/'
  );
  return data;
};

export const useSupplierChoices = (enabled = true) => {
  return useQuery(['suppliers'], getSuppliers, {
    enabled,
    staleTime: Infinity,
  });
};

const getSupplierContacts = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/supplier_contacts/'
  );
  return data;
};

export const useSupplierContactChoices = (enabled = true) => {
  return useQuery(['supplier-contacts'], getSupplierContacts, {
    enabled,
    staleTime: Infinity,
  });
};

// ******* Get company products for roof *********
const getRoofProducts = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/roof_products/'
  );
  return data;
};

export const useRoofProducts = (enabled = true) => {
  return useQuery(['roof-products'], getRoofProducts, {
    enabled,
    staleTime: Infinity,
  });
};

const getInsuranceCompanies = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/insurance_companies/'
  );
  return data;
};

export const useInsuranceCompanies = (enabled = true) => {
  return useQuery(['insurance-companies'], getInsuranceCompanies, {
    enabled,
    staleTime: Infinity,
  });
};

const getMortgageCompanies = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/mortgage_companies/'
  );
  return data;
};

export const useMortgageCompanies = (enabled = true) => {
  return useQuery(['mortgage-companies'], getMortgageCompanies, {
    enabled,
    staleTime: Infinity,
  });
};

const getRepRegions = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/rep_regions/'
  );
  return data;
};

export const useRepRegions = () => {
  return useQuery(['rep-regions'], getRepRegions, {
    staleTime: Infinity,
  });
};

const getRegions = async (q?: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/regions/',
    q
  );
  return data;
};

export const useRegions = (q?: Record<string, any>) => {
  return useQuery(['regions', q], () => getRegions(q), {
    staleTime: Infinity,
  });
};

const getRLColors = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/roof_template_colors/'
  );
  return data;
};

export const useRLColorChoices = () => {
  return useQuery(['rl-colors'], getRLColors, { staleTime: Infinity });
};

const getActiveEmployees = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/active_employees/'
  );
  return data;
};

export const useActiveEmployeeChoices = () => {
  return useQuery(['active-employees'], getActiveEmployees, {
    staleTime: Infinity,
  });
};

const getActiveEmployeesForCalendar = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/active_employees_for_calendar/'
  );
  return data;
};

export const useActiveEmployeeForCalendarChoices = () => {
  return useQuery(
    ['active-employees', 'calendar'],
    getActiveEmployeesForCalendar,
    {
      staleTime: Infinity,
    }
  );
};

const getActiveUsers = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/active_users/'
  );
  return data;
};

export const useActiveUserChoices = () => {
  return useQuery(['active-users'], getActiveUsers, {
    staleTime: Infinity,
  });
};

const getDocumentTemplates = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/doc_templates/',
    q
  );
  return data;
};

const getLightDocumentTemplates = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/light_doc_templates/',
    q
  );
  return data;
};

export const useDocumentTemplates = (q: Record<string, any> = {}) => {
  return useQuery(['legacy-doc-templates', q], () => getDocumentTemplates(q), {
    staleTime: Infinity,
  });
};

export const useLightDocumentTemplates = (q: Record<string, any> = {}) => {
  return useQuery(
    ['light-doc-templates', q],
    () => getLightDocumentTemplates(q),
    {
      staleTime: Infinity,
    }
  );
};

const getTitles = async () => {
  const { data } = await apiService.get('/api/light/company-resources/titles/');
  return data;
};

export const useTitles = () => {
  return useQuery(['emp-titles'], getTitles, { staleTime: Infinity });
};

const getTeams = async (q?: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/teams/',
    q
  );
  return data;
};

export const useTeams = (q?: Record<string, any>) => {
  return useQuery(['teams', q], () => getTeams(q), { staleTime: Infinity });
};

const getGroups = async (q?: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/groups/',
    q
  );
  return data;
};

export const useGroups = (q?: Record<string, any>) => {
  return useQuery(['groups', q], () => getGroups(q), { staleTime: Infinity });
};

const getNotificationGroups = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/notification_groups/'
  );
  return data;
};

export const useNotificationGroups = () => {
  return useQuery(['notification-groups'], getNotificationGroups, {
    staleTime: Infinity,
  });
};

const getOverheadPctChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/job_overhead_pct_choices/'
  );
  return data;
};

export const useOverheadPctChoices = () => {
  return useQuery(['overhead-pcts'], getOverheadPctChoices, {
    staleTime: Infinity,
  });
};

const getCallTags = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/call_tags/'
  );
  return data;
};

export const useCallTags = () => {
  return useQuery(['call-tags'], getCallTags, {
    staleTime: Infinity,
  });
};

const getCallOutcomes = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/call_outcomes/'
  );
  return data;
};

export const useCallOutcomes = () => {
  return useQuery(['call-outcomes'], getCallOutcomes, {
    staleTime: Infinity,
  });
};

const getPaymentModes = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/payment_mode_choices/'
  );
  return data;
};

export const usePaymentModeChoices = (enabled = true) => {
  return useQuery(['payment-modes'], getPaymentModes, {
    enabled,
    staleTime: Infinity,
  });
};

const getStatus = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/status_choices/'
  );
  return data;
};

export const useStatusChoices = (enabled = true) => {
  return useQuery(['status'], getStatus, {
    enabled,
    staleTime: Infinity,
  });
};

export const resolveJobListFilters = async (filters: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/companies/resolve_job_list_filters/`,
    { filters }
  );
  return data;
};

export const resolvePaymentListFilters = async (
  filters: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/companies/resolve_payment_list_filters/`,
    { filters }
  );
  return data;
};

//********************** Get Company Docs****************************************

const getOrganizationDocs = async ({ ...q }) => {
  const { data } = await apiService.get('/api/light/company-documents/', {
    ...q,
  });
  return data;
};

export const useOrganizationDocs = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['organisation-docs', q],
    ({ pageParam }) => getOrganizationDocs({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

//************ Get Company Stats**********

const getOrganizationStat = async () => {
  const { data } = await apiService.get('/api/light/companies/stats/');
  return data;
};

export const useOrganizationStat = () => {
  return useQuery(['organisation-stat'], getOrganizationStat, {
    staleTime: Infinity,
  });
};

const getDocumentSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/document_settings/'
  );
  return data;
};

export const useDocumentSettings = () => {
  return useQuery(['organisation-document-settings'], getDocumentSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

//********************** Contact Info ********************************
const getContactInfo = async () => {
  const { data } = await apiService.get(`/api/light/companies/contact_info/`);
  return data;
};

export const useContactInfo = () => {
  return useQuery(['contact-info'], getContactInfo, {
    staleTime: 5 * 60 * 1000,
  });
};

//********************** Job Settings ********************************
const getJobSettings = async () => {
  const { data } = await apiService.get(`/api/light/companies/job_settings/`);
  return data;
};

export const useJobSettings = () => {
  return useQuery(['job-settings'], getJobSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getV2Settings = async () => {
  const { data } = await apiService.get(`/api/light/companies/v2_settings/`);
  return data;
};

export const useV2Settings = () => {
  return useQuery(['v2-settings'], getV2Settings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getEstimateSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/estimate_settings/'
  );
  return data;
};

export const useEstimateSettings = () => {
  return useQuery(['estimate-settings'], getEstimateSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getPaymentSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/payment_settings/'
  );
  return data;
};

export const usePaymentSettings = () => {
  return useQuery(['payment-settings'], getPaymentSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getExpenseSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/expense_settings/'
  );
  return data;
};

export const useExpenseSettings = () => {
  return useQuery(['expense-settings'], getExpenseSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getAdvancedSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/advanced_settings/'
  );
  return data;
};

export const useAdvancedSettings = () => {
  return useQuery(['advanced-settings'], getAdvancedSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

export const updateDocumentSettings = async (payload: Record<string, any>) => {
  const { data } = await apiService.patch(
    '/api/light/companies/document_settings/',
    payload
  );
  return data;
};

const getCommissionsSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/commission_settings/'
  );
  return data;
};

export const useCommissionsSettings = () => {
  return useQuery(['commission-settings'], getCommissionsSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getEmailSettings = async () => {
  const { data } = await apiService.get('/api/light/companies/email_settings/');
  return data;
};

export const useEmailSettings = () => {
  return useQuery(['email-settings'], getEmailSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getNotesSettings = async () => {
  const { data } = await apiService.get('/api/light/companies/note_settings/');
  return data;
};

export const useNotesSettings = () => {
  return useQuery(['notes-settings'], getNotesSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getTodoSettings = async () => {
  const { data } = await apiService.get('/api/light/companies/todo_settings/');
  return data;
};

export const useTodoSettings = () => {
  return useQuery(['todo-settings'], getTodoSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getReportSettings = async () => {
  const { data } = await apiService.get(
    '/api/light/companies/report_settings/'
  );
  return data;
};

export const useReportSettings = () => {
  return useQuery(['report-settings'], getReportSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

const getCallSettings = async () => {
  const { data } = await apiService.get('/api/light/companies/call_settings/');
  return data;
};

export const useCallSettings = () => {
  return useQuery(['call-settings'], getCallSettings, {
    staleTime: 5 * 60 * 1000,
  });
};

//********************** Survey Choices ********************************

const getSurveyChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/survey_choices/'
  );
  return data;
};

export const useSurveyChoices = () =>
  useQuery(['survey-choices'], getSurveyChoices, {
    staleTime: Infinity,
  });

const getReportChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/report_choices/'
  );
  return data;
};

export const useReportChoices = () => {
  return useQuery(['report-choices'], getReportChoices, {
    staleTime: Infinity,
  });
};

const getActiveRepsChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/active_reps/'
  );
  return data;
};

export const useActiveRepsChoices = () => {
  return useQuery(['active-reps-choices'], getActiveRepsChoices, {
    staleTime: Infinity,
  });
};

const getTLCommissionStructureChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/tl_commission_structure_choices/'
  );
  return data;
};

export const useTLCommissionStructureChoices = () => {
  return useQuery(
    ['tl-commission-structure-choices'],
    getTLCommissionStructureChoices,
    {
      staleTime: Infinity,
    }
  );
};

const getRepCommissionStructureChoices = async (q?: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/company-resources/rep_commission_structure_choices/',
    q
  );
  return data;
};

export const useRepCommissionStructureChoices = (q?: Record<string, any>) => {
  return useQuery(
    ['rep-commission-structure-choices', q],
    () => getRepCommissionStructureChoices(q),
    {
      staleTime: Infinity,
    }
  );
};

const getAvailableNotificationChoices = async (id: string | number) => {
  const { data } = await apiService.get(
    `/api/light/employees/${id}/available_notification_groups/`
  );
  return data;
};

export const useAvailableNotificationChoices = (id: string | number) => {
  return useQuery(
    ['available-notification-choices', id?.toString()],
    () => getAvailableNotificationChoices(id),
    {
      staleTime: Infinity,
    }
  );
};

const getNoteTemplatesChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/note_template_choices/'
  );
  return data;
};

export const useNoteTemplatesChoices = () => {
  return useQuery(['note-templates-choices'], getNoteTemplatesChoices, {
    staleTime: Infinity,
  });
};

const getEventTemplatesChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/event_template_choices/'
  );
  return data;
};

export const useEventTemplatesChoices = () => {
  return useQuery(['event-templates-choices'], getEventTemplatesChoices, {
    staleTime: Infinity,
  });
};

const getEmailTemplatesChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/email_template_choices/'
  );
  return data;
};

export const useEmailTemplatesChoices = () => {
  return useQuery(['email-templates-choices'], getEmailTemplatesChoices, {
    staleTime: Infinity,
  });
};

const getReportContextChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/report_context_choices/'
  );
  return data;
};

export const useReportContextChoices = () => {
  return useQuery(['report-context-choices'], getReportContextChoices, {
    staleTime: Infinity,
  });
};

const getReportTagsChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/report_tag_choices/'
  );
  return data;
};

export const useReportTagsChoices = () => {
  return useQuery(['report-tag-choices'], getReportTagsChoices, {
    staleTime: Infinity,
  });
};

const getPaymentTypeChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/payment_type_choices/'
  );
  return data;
};

export const usePaymentTypeChoices = (enabled = true) => {
  return useQuery(['payment-type-choices'], getPaymentTypeChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getCompanyDocsChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/company_docs/'
  );
  return data;
};

export const useCompanyDocsChoices = (enabled = true) => {
  return useQuery(['company-docs-choices'], getCompanyDocsChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getSubregionChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/subregion_choices/'
  );
  return data;
};

export const useSubregionChoices = (enabled = true) => {
  return useQuery(['sub-regions'], getSubregionChoices, {
    enabled,
    staleTime: Infinity,
  });
};

export const importRoofTemplatesForManufacturers = async (
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/companies/import_roof_templates_for_manufacturers/`,
    payload
  );
  return data;
};

export const updateSalesRabbitWebhook = async (
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/companies/salesrabbit_webhook/`,
    payload
  );
  return data;
};

const getEstimateTemplateChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/estimate_templates/'
  );
  return data;
};

export const useEstimateTemplates = (enabled = true) => {
  return useQuery(['estimate-templates'], getEstimateTemplateChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getStripeConnectLink = async () => {
  const { data } = await apiService.get(
    `/api/light/companies/connect_stripe_url/`
  );
  return data;
};

export const useStripeConnectLink = () => {
  return useQuery(['stripe-connect-link'], getStripeConnectLink, {
    staleTime: 0,
  });
};

const getIntegrationMeta = async () => {
  const { data } = await apiService.get(
    `/api/light/companies/integration_meta/`
  );
  return data;
};

export const useIntegrationMeta = () => {
  return useQuery(['integration-meta', 'company'], getIntegrationMeta, {
    staleTime: 0,
  });
};

const getStripeDetails = async () => {
  const { data } = await apiService.get(
    `/api/light/companies/stripe_connect_details/`
  );
  return data;
};

export const useStripeDetails = () => {
  return useQuery(['stripe-details'], getStripeDetails, {
    staleTime: 10 * 60 * 1000,
  });
};

export const verifyStripe = async (id: string) => {
  const { data } = await apiService.post(
    `/api/company-stripe/${id}/verify_stripe_account_url/`
  );
  return data;
};

export const updateStripeStatus = async () => {
  const { data } = await apiService.post(
    `/api/company-stripe/update_connect_status/`
  );
  return data;
};

export const getCompanyPaymentMethods = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/company-payment-methods/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useCompanyPaymentMethods = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['company-payment-methods', q, { page }],
    () => getCompanyPaymentMethods({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

export const addPaymentMethodForCompany = async (
  payload: Record<string, any>,
  q: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/companies/payment-source/table/`,
    payload,
    q
  );
  return data;
};

export const deletePaymentMethodForCompany = async (id: number) => {
  await apiService.delete(`/api/light/company-payment-methods/${id}/`);
  return true;
};

export const makePaymentMethodDefault = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/company-payment-methods/${id}/default/`
  );
  return data;
};

export const getInvoices = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/company-invoices/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useInvoices = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['company-invoices', q],
    ({ pageParam }) => getInvoices({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopInvoices = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['company-invoices', 'desktop', q, { page }],
    () => getInvoices({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

export const getSubscriptions = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/subscriptions/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useSubscriptions = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['subscriptions', q],
    ({ pageParam }) => getSubscriptions({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopSubscriptions = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['subscriptions', 'desktop', q, { page }],
    () => getSubscriptions({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

export const cancelSubscription = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/light/companies/cancel_subscription/',
    payload
  );
  return data;
};

const getActiveCompanyChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/active_companies/'
  );
  return data;
};

export const useActiveCompanies = (enabled = true) => {
  return useQuery(['companies', 'active'], getActiveCompanyChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getMyCompanyChoices = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/employees/${id}/my_companies/`
  );
  return data;
};

export const useMyCompanies = (id: string) => {
  return useQuery(['companies', 'my', id], () => getMyCompanyChoices(id), {
    staleTime: 0,
  });
};

export const enableNearmap = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/nearmap/integration/enable/`,
    payload
  );
  return data;
};

const getNearmapDetails = async () => {
  const { data } = await apiService.get(
    `/api/light/companies/nearmap_details/`
  );
  return data;
};

export const useNearmapDetails = () => {
  return useQuery(['nearmap-details'], getNearmapDetails, {
    staleTime: 30 * 60 * 1000,
  });
};

export const revokeNearmap = async () => {
  const { data } = await apiService.get(`/api/nearmap/integration/revoke/`);
  return data;
};

export const purchaseNearmapCredits = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/nearmap/integration/surveys_purchase/`,
    payload
  );
  return data;
};

const getPaymentLinkMethodChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/payment_link_method_choices/'
  );
  return data;
};

export const usePaymentLinkMethodChoices = (enabled = true) => {
  return useQuery(['payment-link-methods'], getPaymentLinkMethodChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getNearmapPlanChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/nearmap_plans/'
  );
  return data;
};

export const useNearmapPlanChoices = (enabled = true) => {
  return useQuery(['nearmap-plan-choices'], getNearmapPlanChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getWebhookPushRestrictionChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/webhook_push_restriction_choices/'
  );
  return data;
};

export const useWebhookPushRestrictionChoices = () => {
  return useQuery(
    ['webhook-push-restriction-choices'],
    getWebhookPushRestrictionChoices,
    {
      staleTime: Infinity,
    }
  );
};

const getWebhookTriggerChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/webhook_trigger_choices/'
  );
  return data;
};

export const useWebhookTriggerChoices = () => {
  return useQuery(['webhook-trigger-choices'], getWebhookTriggerChoices, {
    staleTime: Infinity,
  });
};

const getWebhookClaimStatusChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/webhook_claim_status_choices/'
  );
  return data;
};

export const useWebhookClaimStatusChoices = () => {
  return useQuery(
    ['webhook-claim-status-choices'],
    getWebhookClaimStatusChoices,
    {
      staleTime: Infinity,
    }
  );
};

const getCustomChecklistItemsChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/custom_checklist_item_choices/'
  );
  return data;
};

export const useCustomChecklistItemsChoices = () => {
  return useQuery(
    ['custom-checklist-items-choices'],
    getCustomChecklistItemsChoices,
    {
      staleTime: Infinity,
    }
  );
};

export const updateRoofLayoutSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/roof_template_settings/`,
    payload
  );
  return data;
};

export const updateJobSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/job_setting_lite/`,
    payload
  );
  return data;
};

export const updateV2Settings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/v2_settings/`,
    payload
  );
  return data;
};

export const updateEstimateSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/estimate_settings/`,
    payload
  );
  return data;
};

export const updatePaymentSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/payment_settings/`,
    payload
  );
  return data;
};

export const updateExpenseSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/material_work_order/`,
    payload
  );
  return data;
};

export const updateAdvancedSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/advanced_settings/`,
    payload
  );
  return data;
};

export const updateCommissionSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/commission_settings/`,
    payload
  );
  return data;
};

export const updateEmailSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/email_settings/`,
    payload
  );
  return data;
};

export const updateNotesSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/note_settings/`,
    payload
  );
  return data;
};

export const updateTodoSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/todo_settings/`,
    payload
  );
  return data;
};

export const updateReportSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/report_settings/`,
    payload
  );
  return data;
};

export const updateCallSettings = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/call_settings/`,
    payload
  );
  return data;
};

const getAddLeadCustomFields = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/add_lead_custom_fields/'
  );
  return data;
};

export const useAddLeadCustomFields = () => {
  return useQuery(['custom-fields', 'add-lead'], getAddLeadCustomFields, {
    staleTime: 5 * 60 * 1000,
  });
};

const getCustomFields = async () => {
  const { data } = await apiService.get('/api/light/companies/custom_fields/');
  return data;
};

export const useCustomFields = () => {
  return useQuery(['custom-fields', 'all'], getCustomFields, {
    staleTime: 5 * 60 * 1000,
  });
};

export const createCustomField = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/light/companies/create_custom_field/',
    payload
  );
  return data;
};

export const updateCustomField = async (payload: Record<string, any>) => {
  const { data } = await apiService.put(
    `/api/light/companies/update_custom_field/`,
    payload
  );

  return data;
};

export const deleteCustomField = async (key: string) => {
  await apiService.delete(
    `/api/light/companies/delete_custom_field/?key=${key}`
  );
  return true;
};

export const updateContactInfo = async (
  id: string,
  contact_type: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/companies/${id}/${contact_type}/`,
    payload
  );
  return data;
};

export const getStateChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/state_choices/'
  );
  return data;
};

export const useStateChoices = () => {
  return useQuery(['state-choices'], getStateChoices, {
    staleTime: Infinity,
  });
};

export const getVoidChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/company-resources/void_choices/'
  );
  return data;
};

export const useVoidChoices = () => {
  return useQuery(['void-choices'], getVoidChoices, {
    staleTime: Infinity,
  });
};
