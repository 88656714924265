import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createEstimateFromDrawing,
  createLightEstimateFromDrawing,
} from 'queries/estimates';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import Icon, { Loader } from 'components/Icon';
import {
  createLightMatorderFromDrawing,
  createMatorderFromDrawing,
} from 'queries/matorders';
import {
  createLightWorkorderFromDrawing,
  createWorkorderFromDrawing,
} from 'queries/workorders';
import clx from 'classnames';

type AppProps = {
  closeModal: () => void;
  payload: Record<string, any>;
};

enum DoneEnum {
  Estiamte = 'estimate',
  Matorder = 'matorder',
  Workorder = 'workorder',
}

const CreateEstimateAndOrders: React.FC<AppProps> = ({
  closeModal,
  payload,
}) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const [estId, setEstId] = useState(null);
  const [done, setDone] = useState<DoneEnum[]>([DoneEnum.Estiamte]);
  const navigate = useNavigate();

  const isLight = payload?.version === 'v2';

  const addWorkorders = useMutation(
    () =>
      isLight
        ? createLightWorkorderFromDrawing(jobId, payload)
        : createWorkorderFromDrawing(jobId, payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['workorders']);
        queryClient.invalidateQueries(['matorders']);
        queryClient.invalidateQueries(['estimates']);
        queryClient.invalidateQueries(['job-detail', jobId]);

        setDone((d) => [...d, DoneEnum.Workorder]);
        closeModal();
        return navigate(`/jobs/${jobId}/estimates/${estId}`);
      },
    }
  );

  const addMatorders = useMutation(
    () =>
      isLight
        ? createLightMatorderFromDrawing(jobId, payload)
        : createMatorderFromDrawing(jobId, payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        setDone((d) => [...d, DoneEnum.Matorder]);
        addWorkorders.mutate();
      },
    }
  );

  const addEstimate = useMutation(
    () =>
      isLight
        ? createLightEstimateFromDrawing(jobId, payload)
        : createEstimateFromDrawing(jobId, payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res) => {
        setDone((d) => [...d, DoneEnum.Estiamte]);
        setEstId(res);
        addMatorders.mutate();
      },
    }
  );

  useEffect(() => {
    addEstimate.mutate();
  }, []);

  const renderItem = (
    label: string,
    icon: string,
    loading: boolean,
    complete: boolean
  ) => {
    let _label = '';
    if (loading) {
      _label = `Creating ${label}`;
    } else if (complete) {
      _label = `${label} Created`;
    } else {
      _label = `${label}`;
    }

    return (
      <div
        className={clx(
          'border rounded-md px-2 py-3 mb-4 flex justify-between',
          { 'border-success': complete }
        )}
      >
        <div>
          <div className="text-text v-center">
            <div className="w-8">
              <Icon
                name={icon}
                size={20}
                className={clx({
                  'text-success': complete,
                  'text-text-light': !complete,
                })}
              />
            </div>
            <div className={clx({ 'text-success': complete })}>{_label}</div>
          </div>
        </div>

        <div>
          {(() => {
            if (loading) {
              return <Loader className="text-primary text-sm" />;
            }
            if (complete) {
              return <Icon name="check-circle" className="text-success" />;
            }
            <Icon name="caret-right-outline" className="text-text" />;
          })()}
        </div>
      </div>
    );
  };

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="page-heading">Creating Estimate & Orders</div>
      </div>
      <div className="mt-6">
        <div className="mb-2 key">{done.length} of 3 completed</div>
        {renderItem(
          'Estimate',
          'calculator',
          addEstimate.isLoading,
          done.includes(DoneEnum.Estiamte)
        )}
        {renderItem(
          'Matorder',
          'material',
          addMatorders.isLoading,
          done.includes(DoneEnum.Matorder)
        )}
        {renderItem(
          'Workorder',
          'workorder',
          addWorkorders.isLoading,
          done.includes(DoneEnum.Workorder)
        )}
      </div>
    </div>
  );
};

export default CreateEstimateAndOrders;
