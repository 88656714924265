import axios, { AxiosInstance } from 'axios';
import { getItem } from './localstorage';
import { SESSION_KEY, IMPERSONATE_SESSION_KEY } from './constants';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const PUBLIC_ROUTES = ['/login/', '/register/', '/forgot_password/'];

class ApiService {
  session: AxiosInstance;

  constructor() {
    this.session = axios.create({
      baseURL,
      withCredentials: false,
    });

    this.session.interceptors.request.use((config) => {
      if (PUBLIC_ROUTES.some((s) => config?.url?.endsWith(s))) {
        return {
          ...config,
          headers: {
            ...config.headers,
            'X-Platform-Version': 'light',
          },
        };
      }

      const sid = getItem(IMPERSONATE_SESSION_KEY) || getItem(SESSION_KEY);
      if (!sid) {
        return {
          ...config,
          headers: {
            ...config.headers,
            'X-Platform-Version': 'light',
          },
        };
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          'X-Platform-Version': 'light',
          Authorization: `Bearer ${sid}`,
        },
      };
    });
  }

  updateContentType = (contentType: string) => {
    this.session.defaults.headers.common['Content-Type'] = contentType;
  };

  async get(url: string, params: Record<string, any> = {}) {
    return this.session.get(url, { params });
  }

  async post(
    url: string,
    payload: Record<string, any> = {},
    q: Record<string, any> = {}
  ) {
    return this.session.post(url, payload, { params: q });
  }

  async put(
    url: string,
    payload: Record<string, any> = {},
    q: Record<string, any> = {}
  ) {
    return this.session.put(url, payload, { params: q });
  }

  async patch(
    url: string,
    payload: Record<string, any> = {},
    q: Record<string, any> = {}
  ) {
    return this.session.patch(url, payload, { params: q });
  }

  async delete(url: string, q: Record<string, any> = {}) {
    return this.session.delete(url, { params: q });
  }
}

export default new ApiService();
